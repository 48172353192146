const roles = Object.freeze({
  ADMIN_TRAINEE: 67,
  ADMIN: 2,
  ENGINEER: 7,
  AUTHS_ADMIN: 69,
  BILLING_ADMIN: 23,
  CALENDAR_ADMIN: 3,
  CONTACT_ADMIN: 6,
  FRONT_DESK_ADMIN: 70,
  HELP_OUT_MODE: 87,
  MANAGER: 18,
  MASS_CANCELLATION: 83,
  MEMBER_TECH_SUPPORT: 65,
  MEDICAL_RECORDS_ADMIN: 68,
  PCP: 21,
  PEDIATRICS: 43,
  PROVIDER: 1,
  RN: 37,
  UCP: 30,
  VMT_AFTER_HOURS: 74,
  VMT_FOCUS: 71,
  VMT: 12,
});

export default roles;
